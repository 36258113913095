<!-- eslint-disable max-lines -->
<template>
  <div data-cy="personal-info-page" class="personal-info-main-container">
    <component
      :is="
        $vuetify.breakpoint.smAndDown
          ? 'personal-info-tabs'
          : 'personal-info-card'
      "
      :is-modal-opened="isModalOpened"
      :jobs="jobs"
      :countries="countries"
      :guest="guest"
      :initial-tab="$route.query === 'address' ? 'address' : 'dados'"
      :photo-id="guest.photoId"
      :config="config"
      :cities="citiesOptions.residence"
      :states="statesOptions.residence"
      :reservation="reservation"
      :utilize-master-guest-data="utilizeMasterGuestData"
      :enable-document-scanner="
        get(config, 'documentData.mainDocument.personalization')
      "
      @use:masterguest="changeUseMasterGuestData"
      @next="onNextTab"
      @submit="$store.dispatch('flow/guest/next')"
      @change="onChange"
      @change:watch-age="watchAge"
      @click:openmodal="isFnrhModalOpen = true"
      @click:back="$router.push({ name: 'home' })"
      @click-scan-document="onClickScanDocument"
      @click:upload-picture-mobile="onClickUploadNewPictureMobile"
      @click:upload="onClickUploadNewPicture"
      @open-info-modal="fnrhInfoModalOpen = true"
      @blur="formatBirthdateDay"
    />
    <take-or-upload-picture-modal
      :opened="takeOrUploadPictureModalOpen"
      @click:take-picture="takePictureModalOpen = true"
      @click:close="takeOrUploadPictureModalOpen = false"
      @change:photo="onChangePhotoId"
      @close="takeOrUploadPictureModalOpen = false"
    />
    <take-picture-modal
      v-if="takePictureModalOpen"
      :opened="takePictureModalOpen"
      :is-loading-face="loadingFace"
      @close="takePictureModalOpen = false"
      @scan:click="onClickCapture"
    />
    <confirm-image-modal
      :opened="confirmImageModalOpen"
      :image="guest.photoUrlMemory || guest.photoUrl"
      :image-source="guest.photoUrlMemory || guest.photoUrl"
      :enable-close="false"
      @click:cancel="onClickCancelImage"
      @click:send-image="onClickSendImage"
    />
    <save-biometrics-modal
      :opened="guest.biometricsAccepted === false"
      @click:register="onAcceptBiometrics"
      @click:no-register="changeGuest({ biometricsAccepted: null })"
    />
    <fnrh-info-modal
      :opened="isFnrhModalOpen"
      @click:close="isFnrhModalOpen = false"
    />
    <scanner-or-upload-modal
      v-if="isScannerOrUploadModalOpen"
      :opened="isScannerOrUploadModalOpen"
      :personalize="get(config, 'documentData.mainDocument.personalization')"
      :guest="guest"
      :current-lang="currentLang.charAt(0).toUpperCase() + currentLang.slice(1)"
      @click:upload-picture="onClickUploadDocument"
      @click:scan-picture="onClickContinue"
      @click:cancel="closeScannerOrUploadModal"
    />
    <upload-image-modal
      :opened="uploadImageModalOpen"
      @click:cancel="closeUploadImageModal"
      @click:continue="onClickConfirmImage"
      @click:send-documents="onClickSendDocuments"
      @change:documents="onChangeDocuments"
      @erase:back-picture="eraseBackDocumentsImage"
      @erase:front-picture="eraseFrontDocumentsImage"
    />
    <upload-additional-modal
      :opened="uploadAdditionalModalOpen"
      @click:cancel="closeUploadImageModal"
      @click:continue="onClickConfirmImage"
      @click:send-documents="onClickSendDocuments"
      @change:documents="onChangeDocuments"
      @erase:back-picture="eraseBackDocumentsImage"
      @erase:front-picture="eraseFrontDocumentsImage"
    />
    <document-scanner-modal
      v-if="documentScannerModalOpen"
      :opened="documentScannerModalOpen"
      :guest="guest"
      @close="closeScannerModal"
      @click:send-documents="onClickSendDocuments"
      @confirm-scanned-document="onClickConfirmDocuments"
    />
    <crop-modal
      v-if="cropModalOpen"
      :opened="cropModalOpen"
      :image="currentImage"
      :guest="guest"
      @close="closeCropModal"
      @click:send-documents="onClickSendDocuments"
      @confirm:image="onConfirmImage"
    />
  </div>
</template>

<script>
import find from "lodash.find"
import get from "lodash.get"
import { onMounted, provide, ref } from "vue"
import { useRouter } from "vue-router/composables"
import {
  useNamespacedActions,
  useNamespacedGetters,
  useNamespacedState,
} from "vuex-composition-helpers/dist"
import { useNamespacedMutations } from "vuex-composition-helpers/dist"

import ConfirmImageModal from "@/components/TakePicture/components/ConfirmImageModal.vue"
import { useStoreAction } from "@/composables"
import { useTracking } from "@/composables/useTracking"

import { useChangeRegion } from "../useChangeRegion"
import { useDocuments } from "../useDocuments"
import DocumentScannerModal from "./components/modals/DocumentScannerModal.vue"
import ScannerOrUploadModal from "./components/modals/ScannerOrUploadModal.vue"
import UploadAdditionalModal from "./components/modals/UploadAdditionalModal.vue"
import UploadImageModal from "./components/modals/UploadImageModal.vue"
import PersonalInfoCard from "./components/PersonalInfoCard.vue"
import PersonalInfoTabs from "./components/PersonalInfoTabs.vue"

export default {
  components: {
    PersonalInfoTabs,
    PersonalInfoCard,
    ScannerOrUploadModal,
    ConfirmImageModal,
    UploadImageModal,
    DocumentScannerModal,
    UploadAdditionalModal,
  },
  // eslint-disable-next-line max-lines-per-function
  setup() {
    const router = useRouter()

    // Modals
    const { currentLang } = useNamespacedState("layout", ["currentLang"])
    const takeOrUploadPictureModalOpen = ref(false)
    const loadingFace = ref(false)
    const takePictureModalOpen = ref(false)
    const confirmImageModalOpen = ref(false)
    const modalAuthorizeDeviceOpen = ref(false)
    const isModalOpened = ref(false)
    const isFnrhModalOpen = ref(false)
    const isScannerOrUploadModalOpen = ref(false)
    const saveBiometricsModalOpen = ref(false)
    const uploadImageModalOpen = ref(false)
    const uploadAdditionalModalOpen = ref(false)
    const fnrhInfoModalOpen = ref(false)
    const currentImage = ref(null)
    const documentScannerModalOpen = ref(false)
    const cropModalOpen = ref(false)

    const { trackEvent } = useTracking()

    // Reservation
    const { reservation } = useNamespacedState("reservation", ["reservation"])

    // Guest
    const { guest, utilizeMasterGuestData, isAdditionalDocument } =
      useNamespacedState("guest", [
        "guest",
        "utilizeMasterGuestData",
        "isAdditionalDocument",
      ])
    const { isQualified } = useNamespacedState("guest/auth", ["isQualified"])

    const {
      countries,
      citiesOptions,
      statesOptions,
      isLoadingCep,
      onChangeCity,
      onChangeState,
      onChangeCountry,
      onChangeZipCode,
    } = useChangeRegion()

    const { changeGuest, setAdditionalDocument } = useNamespacedMutations(
      "guest",
      ["changeGuest", "setAdditionalDocument"]
    )
    const { changeUseMasterGuestData } = useNamespacedActions("guest", [
      "changeUseMasterGuestData",
    ])

    // Guest - Documents
    const {
      saveBackDocumentsImage,
      saveFrontDocumentsImage,
      eraseFrontAndBack,
      eraseBackDocumentsImage,
      eraseFrontDocumentsImage,
    } = useDocuments()

    // Guest - Recognition
    const { faceId } = useNamespacedState("search", ["faceId"])
    const { saveFaceImage } = useStoreAction("guest", "saveFaceImage")
    const { sendImagetoRecognition } = useStoreAction(
      "guest",
      "sendImagetoRecognition"
    )

    // Company - Config
    const { personalInfoConfig: config } = useNamespacedGetters("company", [
      "personalInfoConfig",
    ])

    // Parameters
    const { jobs } = useNamespacedState("parameters", ["jobs"])

    const onChangeNationality = ({ nationalityId }) => {
      const country = find(countries.value, {
        code: nationalityId,
      })

      const value = country[`name_${[currentLang.value]}`] ?? country.name

      changeGuest({ nationalityName: value })
    }

    /**
     * Metodos de alteração dos campos e regra para atualizar consultas
     * caso seja alterado campos específicos
     */
    const onChange = (chunk) => {
      changeGuest(chunk)

      if ("nationalityId" in chunk) onChangeNationality(chunk)
      if ("countryId" in chunk) onChangeCountry(chunk)
      if ("stateId" in chunk) onChangeState(chunk)
      if ("cityId" in chunk) onChangeCity(chunk)
      if ("zipCode" in chunk) onChangeZipCode(chunk)

      if (
        "birthdateDay" in chunk ||
        "birthdateMonth" in chunk ||
        "birthdateYear" in chunk
      ) {
        {
          changeGuest({
            birthdate: `${guest.value.birthdateYear}-${guest.value.birthdateMonth}-${guest.value.birthdateDay}`,
          })
        }
      }
    }

    const onClickUploadNewPicture = () => {
      changeGuest({ biometricsAccepted: null })
      takeOrUploadPictureModalOpen.value = true
      isModalOpened.value = true
      trackEvent("click_upload_new_picture")
    }

    const onClickUploadNewPictureMobile = () => {
      trackEvent("click_upload_new_picture")
      changeGuest({ biometricsAccepted: null })
      get(
        config.value,
        "personalData.personalization.instructions.enableNewPicture"
      ) !== true
        ? router.push({ name: "take-picture" })
        : router.push({ name: "take-picture-instructions" })
    }

    const onAcceptBiometrics = () => {
      changeGuest({ biometricsAccepted: true })
      trackEvent("click_accepted_biometrics")

      if (
        guest.value.photoUrl.includes("temp") &&
        guest.value.biometricsAccepted
      ) {
        if (isQualified.value) {
          sendImagetoRecognition()
        }
      }
    }

    const onClickConfirmDocuments = async (image, tag) => {
      if (!isAdditionalDocument.value) {
        if (tag === "front") {
          changeGuest({ "documents.frontImageMemory": image })
          await saveFrontDocumentsImage()
        } else {
          changeGuest({ "documents.backImageMemory": image })
          await saveBackDocumentsImage()

          documentScannerModalOpen.value = false
        }
      } else {
        if (tag === "front") {
          changeGuest({
            "additionalDocument.frontImageMemory": image,
          })
          await saveFrontDocumentsImage()
        } else {
          changeGuest({
            "additionalDocument.backImageMemory": image,
          })
          await saveBackDocumentsImage()

          documentScannerModalOpen.value = false
        }
      }

      isScannerOrUploadModalOpen.value = false
      isModalOpened.value = false
    }

    const onChangeDocuments = (image) => {
      currentImage.value = image

      documentScannerModalOpen.value = false
      cropModalOpen.value = true
      isModalOpened.value = true
    }

    const onClickContinue = () => {
      if (isScannerOrUploadModalOpen.value) {
        changeGuest({
          "additionalDocument.frontImageMemory": "",
          "additionalDocument.front": "",
          "additionalDocument.frontImage": "",
          "additionalDocument.backImageMemory": "",
          "additionalDocument.back": "",
          "additionalDocument.backImage": "",
        })
      } else {
        changeGuest({
          "documents.frontImageMemory": "",
          "documents.front": "",
          "documents.frontImage": "",
          "documents.backImageMemory": "",
          "documents.back": "",
          "documents.backImage": "",
        })
      }

      documentScannerModalOpen.value = true
      isScannerOrUploadModalOpen.value = false
      isModalOpened.value = true
    }

    const onClickConfirmImage = () => {
      uploadImageModalOpen.value = false
      uploadAdditionalModalOpen.value = false
      isScannerOrUploadModalOpen.value = false
      isModalOpened.value = false
    }

    const closeCropModal = () => {
      cropModalOpen.value = false
      isModalOpened.value = false
    }

    const closeScannerModal = () => {
      documentScannerModalOpen.value = false
      isModalOpened.value = false
    }

    const closeUploadImageModal = () => {
      if (!isAdditionalDocument.value) {
        changeGuest({
          photoUrlMemory: "",
          photoId: "",
        })
        uploadImageModalOpen.value = false
        isModalOpened.value = false
      } else {
        uploadAdditionalModalOpen.value = false
        isModalOpened.value = false
      }
    }

    const closeScannerOrUploadModal = () => {
      isScannerOrUploadModalOpen.value = false
      isModalOpened.value = false
    }

    const onClickSendDocuments = () => {
      uploadImageModalOpen.value = false
      isScannerOrUploadModalOpen.value = false
      isModalOpened.value = false
    }

    const onClickUploadDocument = () => {
      trackEvent("click_upload_document")
      eraseFrontAndBack()
      isModalOpened.value = true
      isScannerOrUploadModalOpen.value = false

      if (isAdditionalDocument.value) {
        uploadAdditionalModalOpen.value = true
      } else {
        uploadImageModalOpen.value = true
      }
    }

    const onClickScanDocument = (event) => {
      if (event === true) {
        setAdditionalDocument(true)
      }
      const detectMob = () => {
        return window.innerWidth <= 800 && window.innerHeight <= 915
      }
      if (
        get(
          config.value,
          "documentData.mainDocument.personalization.enableDocumentInstruction"
        ) !== true &&
        detectMob()
      ) {
        documentScannerModalOpen.value = true
      } else {
        isScannerOrUploadModalOpen.value = true
        isModalOpened.value = true
      }
    }

    const onClickUpload = () => {
      takeOrUploadPictureModalOpen.value = true
    }

    const onClickCapture = async (image) => {
      loadingFace.value = true
      changeGuest({
        photoUrlMemory: image,
      })
      await saveFaceImage()
      loadingFace.value = false

      if (!guest.value.biometricsAccepted === true) {
        changeGuest({
          biometricsAccepted: false,
        })
      }
      takeOrUploadPictureModalOpen.value = false
      takePictureModalOpen.value = false
    }

    const onClickSendImage = async () => {
      takePictureModalOpen.value = false
      takeOrUploadPictureModalOpen.value = false
      if (!guest.value.biometricsAccepted === true) {
        changeGuest({
          biometricsAccepted: false,
        })
      }
      confirmImageModalOpen.value = false
      await saveFaceImage()
    }

    const onChangePhotoId = (event) => {
      changeGuest({
        photoId: event.photoId,
        photoUrlMemory: event.photoUrl,
      })
      takeOrUploadPictureModalOpen.value = false
      confirmImageModalOpen.value = true
    }

    const onClickCancelImage = () => {
      changeGuest({
        photoId: "",
        photoUrlMemory: "",
      })
      confirmImageModalOpen.value = false
      takePictureModalOpen.value = false
      takeOrUploadPictureModalOpen.value = false
    }

    const onConfirmImage = async (image, tag) => {
      cropModalOpen.value = false
      isModalOpened.value = true
      if (isAdditionalDocument.value) {
        if (tag === "front") {
          changeGuest({
            "additionalDocument.frontImageMemory": image,
          })

          await saveFrontDocumentsImage()
        } else {
          changeGuest({
            "additionalDocument.backImageMemory": image,
          })

          await saveBackDocumentsImage()
        }
      } else {
        if (tag === "front") {
          changeGuest({
            "documents.frontImageMemory": image,
          })

          await saveFrontDocumentsImage()
        } else {
          changeGuest({
            "documents.backImageMemory": image,
          })

          await saveBackDocumentsImage()
        }
      }
    }

    const watchAge = () => {
      const currentDate = new Date()
      const isYoungerThanEighteen =
        currentDate.getFullYear() - guest.value.birthdateYear < 18

      if (isYoungerThanEighteen && !guest.value.masterGuest) {
        onChange({ isChild: true })
        return
      }

      onChange({ isChild: false })
    }

    const formatBirthdateDay = () => {
      if (guest.value.birthdateDay.length === 1) {
        onChange({
          birthdateDay: "0" + guest.value.birthdateDay,
        })
      }
    }

    const onNextTab = (tab) => trackEvent("substep_personal_info_" + tab)
    onMounted(() => trackEvent("step_personal_info"))

    //providers
    provide("isLoadingCep", isLoadingCep)

    return {
      currentLang,
      get,

      reservation,
      config,
      jobs,
      countries,
      guest,
      changeUseMasterGuestData,
      changeGuest,
      statesOptions,
      citiesOptions,
      isFnrhModalOpen,
      isScannerOrUploadModalOpen,
      isLoadingCep,
      faceId,
      saveBiometricsModalOpen,
      modalAuthorizeDeviceOpen,
      confirmImageModalOpen,
      takeOrUploadPictureModalOpen,
      takePictureModalOpen,
      fnrhInfoModalOpen,
      uploadImageModalOpen,
      documentScannerModalOpen,
      currentImage,
      cropModalOpen,
      isModalOpened,
      utilizeMasterGuestData,
      loadingFace,

      onNextTab,
      onChange,
      onClickScanDocument,
      onClickUpload,
      onClickCapture,
      onClickSendImage,
      onClickCancelImage,
      onChangePhotoId,
      onClickSendDocuments,
      onClickUploadDocument,
      onClickConfirmDocuments,
      onConfirmImage,
      onChangeDocuments,
      onClickContinue,
      onClickConfirmImage,
      closeCropModal,
      closeScannerModal,
      closeUploadImageModal,
      closeScannerOrUploadModal,
      watchAge,
      formatBirthdateDay,

      eraseBackDocumentsImage,
      eraseFrontDocumentsImage,
      uploadAdditionalModalOpen,
      setAdditionalDocument,
      onClickUploadNewPicture,
      onAcceptBiometrics,
      onClickUploadNewPictureMobile,
    }
  },
}
</script>
